import React from 'react'
import { useNavigate } from 'react-router-dom'

function Selection(props) {
  const [streamKey, setStreamKey] = React.useState('')
  const navigate = useNavigate()

  const onStreamKeyChange = e => {
    setStreamKey(e.target.value)
  }
  
  const formKeyDown = e => {
	  if(e.key === "Enter"){
		onWatchStreamClick();
	  }
  }
  
  const onWatchStreamClick = () => {
    navigate(`/${streamKey}`)
  }

  const onPublishStreamClick = () => {
    navigate(`/publish/${streamKey}`)
  }

  return (
    <div className='space-y-4 mx-auto max-w-2xl pt-20 md:pt-24'>
      <form className='rounded-md bg-gray-800 shadow-md p-8' autocomplete="off">
		<input type="hidden" autocomplete="off"/>
        <h2 className="font-light leading-tight text-4xl mt-0 mb-2">Jora.ltd Livestreams</h2>
        <p>To join a stream, enter the streamer's Twitch username below.</p>

        <div className='my-4'>
          <label className='block text-sm font-bold mb-2' htmlFor='streamKey'>
            Streamer's Username
          </label>
          <input className='appearance-none border w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700 border-gray-700 text-white rounded shadow-md placeholder-gray-200' id='streamKey' type='text' placeholder='' onKeyDown={formKeyDown} onChange={onStreamKeyChange} autoFocus autocomplete="new-password"/>
        </div>
        <div className='flex'>
          <button className='py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75' type='button' onClick={onWatchStreamClick}>
            Watch Stream
          </button>
        </div>
      </form>
    </div>
  )
}

export default Selection
