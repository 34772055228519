import { useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { CinemaModeContext } from '../player';

const Header = () => {
  const navigate = useNavigate()
  const { cinemaMode } = useContext(CinemaModeContext);
  const navbarEnabled = !cinemaMode;
  return (
    <div>

      <main className="JoraContainer">
        <Outlet />
      </main>

    </div>
  )
}

export default Header
